.app__header {
  background-color: #ffffff;
  height: 50px;
  position: sticky !important;
  top: 0;
  z-index: 1100;
}

.app__header_scrolled {
  background-color: #ffffff;
  height: 50px;
  position: sticky !important;
  top: 0;
  z-index: 1100;
  box-shadow: -1px 4px 2px -2px gray !important;
  transition: all 0.3s ease-in-out;
}

.app__header_items {
  color: #6b3b25 !important;
  text-transform: uppercase;
  font-weight: 700 !important;
  display: inline-block;
  position: relative;
  text-decoration: none;
}

.app__header_items:hover {
  color: #a81903 !important;
}

.app__header_items:hover::after {
  color: #a81903 !important;
  transform: scaleX(1) !important;
  transform-origin: bottom right left !important;
}

.app__header_items:after {
  content: "" !important;
  position: absolute !important;
  width: 100% !important;
  transform: scaleX(0) !important;
  height: 2px !important;
  bottom: 0 !important;
  left: 0 !important;
  background-color: #a81903 !important;
  transform-origin: bottom right left !important;
  transition: transform 0.25s ease-out !important;
}

.app__header_items_facebook {
  color: #183153 !important;
  text-transform: uppercase;
  font-weight: 700 !important;
  display: inline-block;
  position: relative;
}

.app__header_items_facebook:hover::after {
  color: #a81903 !important;
  transform: scaleX(1) !important;
  transform-origin: bottom right left !important;
}

.app__header_items_facebook:after {
  content: "" !important;
  position: absolute !important;
  width: 100% !important;
  transform: scaleX(0) !important;
  height: 2px !important;
  bottom: 0 !important;
  left: 0 !important;
  background-color: #a81903 !important;
  transform-origin: bottom right left !important;
  transition: transform 0.25s ease-out !important;
}

/* IDE VAN RAKVA */

.app__header_logo_rounded {
  width: 350px;
  position: relative;
  top: 90px;
  z-index: 1100;
  transition: all 0.3s ease-in-out;
  /* object-fit: cover; */
  box-shadow: none !important;
}

.app__header_scrolled .app__header_logo_rounded {
  width: 75px;
  position: block;
  top: 0;
  z-index: 0;
}

.app__header_logo {
  height: 40px;
}

.app__header_mobile {
  background-color: #6b3b25;
  background-image: url("../images/vintage_motif.png");
  background-attachment: fixed;
  background-position: center;
  height: 50px;
  /* position: sticky !important; */
  position: relative;
  top: 0;
  z-index: 1100;
  display: none;
}

.app__header_mobile_scrolled {
  background-color: #6b3b25;
  height: 50px;
  position: sticky !important;
  top: 0;
  z-index: 1100;
  display: none;

  
}

.app__header_mobile .navbar-nav {
  background-color: #6b3b25;
  width: auto;
}

.app__header_mobile_scrolled .navbar-nav {
  background-color: #6b3b25;
  width: auto;
}

@media (max-width: 1200px) {
  .app__header_logo_rounded {
    width: 200px;
    top: 65px;
  }
}

@media (max-width: 1022px) {
  .app__header_logo_rounded {
    width: 75px;
    top: 1px;
  }
}

@media (max-width: 767px) {
  .app__header_mobile {
    display: flex;
  }
  .app__header_mobile_scrolled{
    display: flex;
  }

  .app__header {
    display: none;
  }

  .app__header_scrolled {
    display: none;
  }

  /* .app__header_mobile .navbar-brand {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 25px !important;
  } */

  .app__header_mobile_scrolled .navbar-brand {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 25px !important;
  }

  
  .app__header_mobile_scrolled .app__mobile_logo_rounded{
  max-height: 40px;
  /* display: inline-block; */
  position: relative;
  top: 0px;
}
}


.app__mobile_logo_rounded{
  /* border: red 1px solid; */
  position: absolute;
  top: 0px;
  transition: all 0.1s ease-in-out;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  width: auto;
  height: auto;
  max-width: 270px;
}

.app__mobile_logo_rounded_hide {
  display: none;
}

@media (max-width: 350px){
.app__mobile_logo_rounded{
  max-width: 200px;
}
}